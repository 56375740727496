import React, { useState, useEffect } from 'react';
import './SharedAlbum.css';

const initialImages = [
  '/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp',
  '/images/76d92022-1465-407e-8ed0-746178d27182.webp',
  '/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp',
  '/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp',
  '/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp',
  '/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp',
];

const SharedAlbum = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % initialImages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slideshow">
      <img
        src={initialImages[currentIndex]}
        alt={`Slide ${currentIndex + 1}`}
        className="slideshow-image"
      />
    </div>
  );
};

export default SharedAlbum;

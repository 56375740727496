import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthPage.css';

const RegisterPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleRegister = (e) => {
    // Hier kan een backend-aanroep worden gedaan om de gebruiker in te loggen
    e.preventDefault();
    console.log('Registreren met:', { name, email, password });
    navigate('/login');
  };

  return (
    <div className="auth-page">
      <h2>Registreren</h2>
      <form onSubmit={handleRegister}>
        <label>
          Naam:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </label>
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </label>
        <label>
          Wachtwoord:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </label>
        <button type="submit">Registreren</button>
        <p>Heb je al een account? <a href="/login">Log hier in</a></p>
      </form>
    </div>
  );
};

export default RegisterPage;

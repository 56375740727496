import React, { useState } from 'react';
import './Settings.css';

const Settings = () => {
  const [formData, setFormData] = useState({
    firstName: "Sjoerd",
    lastName: "van der Sluis",
    email: "sjoerd@instaframe.nl",
    password: "",
    confirmPassword: "",
    profilePhoto: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        profilePhoto: file,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.password && formData.password !== formData.confirmPassword) {
      alert("Wachtwoorden komen niet overeen.");
      return;
    }

    console.log("Gegevens succesvol bijgewerkt:", formData);
    alert("Profielgegevens zijn bijgewerkt!");
  };

  return (
    <div className="settings-page">
      <h2>Instellingen</h2>
      <form className="settings-form" onSubmit={handleSubmit}>
        
        {/* Profile Photo Upload Section */}
        <label>
          Profielfoto:
          <input type="file" accept="image/*" onChange={handlePhotoChange} />
        </label>
        {formData.profilePhoto && (
          <div className="profile-photo-preview">
            <img
              src={URL.createObjectURL(formData.profilePhoto)}
              alt="Profielfoto Preview"
              style={{ width: "100px", height: "100px", borderRadius: "50%" }}
            />
          </div>
        )}

        <label>
          Voornaam:
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Achternaam:
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          E-mailadres:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Wachtwoord:
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Nieuw wachtwoord"
          />
        </label>

        <label>
          Bevestig Wachtwoord:
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder="Bevestig nieuw wachtwoord"
          />
        </label>

        <button type="submit">Opslaan</button>
      </form>
    </div>
  );
};

export default Settings;

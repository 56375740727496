import React from 'react';
import { Link } from 'react-router-dom';

const Albums = () => {
  return (
    <div>
      <h1>Albums</h1>
      <div className="albums">
        <div className="album">
          <Link to="/album1-details">
            <img src="/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp" alt="Album 1" className="album-photo" />
            <p className="album-title">Album 1</p>
          </Link>
        </div>
      </div>
      <h1>Albums die met je gedeeld zijn</h1>
      <div className="album">
        <Link to="/sharedalbum">
          <img src="/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp" alt="Album 1" className="album-photo" />
          <p className="album-title">Album 1</p>
        </Link>
      </div>
    </div>
  );
};

export default Albums;

import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './AlbumSingle.css';
import { Link } from 'react-router-dom';

const initialImages = [
  '/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp',
  '/images/76d92022-1465-407e-8ed0-746178d27182.webp',
  '/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp',
  '/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp',
  '/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp',
  '/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp',
];

const Album1 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [sharedEmails, setSharedEmails] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
  const [images, setImages] = useState(initialImages);
  const [error, setError] = useState(""); // Voor foutmelding

  useEffect(() => {
    if (isOpen) {
      preloadImages();
    }
  }, [isOpen]);

  const preloadImages = () => {
    const promises = images.map((src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
      });
    });

    Promise.all(promises).then(() => {
      setImageLoaded(true);
    });
  };

  // Functie voor validatie en delen van album
  const handleShareAlbum = () => {
    // Regex voor e-mailvalidatie
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(newEmail)) {
      setSharedEmails([...sharedEmails, newEmail]);
      setNewEmail("");
      setError(""); // Reset eventuele foutmelding
    } else {
      setError("Voer een geldig e-mailadres in."); // Toon foutmelding bij onjuiste e-mail
    }
  };

  const removeEmail = (email) => {
    setSharedEmails(sharedEmails.filter((e) => e !== email));
  };

  const handleOutsideClick = (e) => {
    if (e.target.className === 'popup') {
      setIsSharePopupOpen(false);
      setIsUploadPopupOpen(false);
    }
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const newImages = Array.from(files).map((file) => URL.createObjectURL(file));
    setImages((prevImages) => [...prevImages, ...newImages]);
    setIsUploadPopupOpen(false);  // Sluit de upload pop-up na toevoegen
  };

  return (
    <div>
      <Link className="keerterug" to="/albums">Keer terug naar alle albums</Link>
      <h1>Album1</h1>
      
      <div className="albums-controls">
        <button onClick={() => setIsSharePopupOpen(true)}>Album delen</button>
        <button onClick={() => setIsUploadPopupOpen(true)}>Bestand uploaden</button>
      </div>

      <div className="albums-grid">
        {images.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Album 1 - ${index + 1}`}
            className="album-photo"
            loading="eager"
            onClick={() => { setIsOpen(true); setPhotoIndex(index); }}
          />
        ))}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}

      {isSharePopupOpen && (
        <div className="popup" onClick={handleOutsideClick}>
          <div className="popup-content">
            <span className="close" onClick={() => setIsSharePopupOpen(false)}>&times;</span>
            <h3>Album delen</h3>
            <input
              type="email"
              placeholder="Vul een e-mailadres in"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <button onClick={handleShareAlbum}>Delen</button>
            {error && <p className="error">{error}</p>} {/* Toon foutmelding als de email onjuist is */}
            <h4>Gedeeld met:</h4>
            <div className="shared-list">
              <ul>
                {sharedEmails.map((email, index) => (
                  <li key={index}>
                    {email} 
                    <button onClick={() => removeEmail(email)}>Verwijderen</button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}

      {isUploadPopupOpen && (
        <div className="popup" onClick={handleOutsideClick}>
          <div className="popup-content">
            <span className="close" onClick={() => setIsUploadPopupOpen(false)}>&times;</span>
            <h3>Bestand uploaden</h3>
            <input type="file" multiple onChange={handleFileUpload} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Album1;

// import React, { useState, useEffect } from "react";
// import axios from "axios";

// function App() {
//   const [producten, setProducten] = useState([]);

//   useEffect(() => {
//     axios.get("https://localhost:7066/api/product")
//       .then(response => {
//         setProducten(response.data);
//       })
//       .catch(error => {
//         console.error("Er is een fout opgetreden:", error);
//       });
//   }, []);

//   return (
//     <div>
//       <h1>Productenlijst</h1>
//       <ul>
//         {producten.map((product, index) => (
//           <li key={index}>{product}</li>
//         ))}
//       </ul>
//     </div>
//   );
// }

// export default App;
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './pages/HomePage';
import Albums from './pages/Albums';
import Footer from './components/Footer';
import ProfilePage from "./pages/ProfilePage";
import Album1 from "./pages/Album1";
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import Settings from './pages/Settings';
import SharedAlbum from './pages/SharedAlbum';

function Content() {
  const location = useLocation();
  const isSharedAlbum = location.pathname === "/sharedalbum";

  return (
    <div className={`content ${isSharedAlbum ? 'shared-album-active' : ''}`}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/albums" element={<Albums />} />
        <Route path="/profilepage" element={<ProfilePage />} />
        <Route path="/album1-details" element={<Album1 />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/sharedalbum" element={<SharedAlbum />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router> 
      <div>
        <Header />
        <Content />
        <Footer />
      </div>
    </Router>
  );
}

export default App;



import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthPage.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    // Hier kan een backend-aanroep worden gedaan om de gebruiker in te loggen
    console.log('Inloggen met:', { email, password });
    navigate('/');
  };

  return (
    <div className="auth-page">
      <h2>Inloggen</h2>
      <form onSubmit={handleLogin}>
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </label>
        <label>
          Wachtwoord:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </label>
        <button type="submit">Inloggen</button>
        <p>Geen account? <a href="/register">Registreer hier</a></p>
      </form>
    </div>
  );
};

export default LoginPage;

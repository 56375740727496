import React, { useState } from 'react';
import './ProfilePage.css';
import { Link } from 'react-router-dom';

const ProfilePage = () => {
  const [albums, setAlbums] = useState([
    { id: 1, name: "Album 1", cover: "/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp" },
  ]);
  const [isAddAlbumPopupOpen, setIsAddAlbumPopupOpen] = useState(false);
  const [newAlbumName, setNewAlbumName] = useState("");
  const [newAlbumCover, setNewAlbumCover] = useState(null);

  const handleAddAlbumClick = () => {
    setIsAddAlbumPopupOpen(true);
  };

  const handleFileChange = (e) => {
    setNewAlbumCover(URL.createObjectURL(e.target.files[0]));
  };

  const handleAddAlbum = () => {
    if (newAlbumName && newAlbumCover) {
      const newAlbum = {
        id: albums.length + 1,
        name: newAlbumName,
        cover: newAlbumCover,
      };
      setAlbums([...albums, newAlbum]);
      setNewAlbumName("");
      setNewAlbumCover(null);
      setIsAddAlbumPopupOpen(false);
    } else {
      alert("Voer een naam en afbeelding in voor het album.");
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target.className === 'popup') {
      setIsAddAlbumPopupOpen(false);
    }
  };

  return (
    <div className="profile-page">
      <div className="profile-header">
        <img 
          className="profile-picture" 
          src="/images/iswanto-arif-DcpqTtUNosw-unsplash.jpg" 
          alt="Profiel"
        />
        <h2>Sjoerd</h2>
        <Link className="url" to="/settings">Profiel bewerken</Link>
      </div>

      <div className="profile-details">
        <h3>Mijn albums</h3>
        <div className="albums">
          {albums.map((album) => (
            <Link to={`/album${album.id}-details`} key={album.id} className="album-link">
              <img src={album.cover} alt={album.name} className="album-photo" />
              <p className="album-title">{album.name}</p>
            </Link>
          ))}
        </div>
        <button className="add-album-button" onClick={handleAddAlbumClick}>Nieuw album toevoegen</button>
      </div>

      {isAddAlbumPopupOpen && (
        <div className="popup" onClick={handleOutsideClick}>
          <div className="popup-content">
            <span className="close" onClick={() => setIsAddAlbumPopupOpen(false)}>&times;</span>
            <h3>Nieuw album Toevoegen</h3>
            <input
              type="text"
              placeholder="Albumnaam"
              value={newAlbumName}
              onChange={(e) => setNewAlbumName(e.target.value)}
            />
            <input type="file" onChange={handleFileChange} />
            {newAlbumCover && <img src={newAlbumCover} alt="Nieuw Album Cover" className="preview-image" />}
            <button onClick={handleAddAlbum}>Toevoegen</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;

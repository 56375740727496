import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="nav-left">
        <button className="burger-menu" onClick={toggleMenu}>
          &#9776;
        </button>
        <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
          <NavLink exact to="/" activeClassName="active">
            Home
          </NavLink>
          <NavLink to="/albums" activeClassName="active">
            Albums
          </NavLink>
        </nav>
      </div>
      <div className="nav-right">
        <div className="user-menu" onClick={toggleDropdown}>
          <img
            src="/images/iswanto-arif-DcpqTtUNosw-unsplash.png"
            alt="User Icon"
            className="user-icon"
          />
          <span className="user-name">Sjoerd</span>
        </div>
        {dropdownOpen && (
          <div className="dropdown">
            <NavLink to="/ProfilePage" activeClassName="active">
              Mijn Profiel
            </NavLink>
            <NavLink to="/Settings" activeClassName="active">
              Instellingen
            </NavLink>
            {/* <NavLink to="/Login" activeClassName="active">
              Inloggen
            </NavLink>
            <NavLink to="/Register" activeClassName="active">
              Registreren
            </NavLink> */}
            <a href="#logout">Uitloggen</a>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import FileUploader from '../components/FileUploader';
import './HomePage.css';
import GetFile from '../components/GetFile';

const HomePage = () => {
  return (
    <div className="homepage">
      <section className="hero-section">
        <img 
          src="/images/ilya-pavlov-XPT-OtA0E-8-unsplash.jpg" 
          alt="Hero" 
          className="hero-image" 
        />
        <div className="hero-overlay">
          <h1>Welkom bij InstaFrame</h1>
          <p>Deel jouw favoriete momenten</p>
        </div>
      </section>

      <section className="content-section">
        <div className="welcome-message">
          <h2>Jouw herinneringen, altijd bij de hand</h2>
          <p>
            Met InstaFrame kun je eenvoudig jouw favoriete momenten vastleggen en delen. 
            Upload, bewerk en sla je foto's op in je persoonlijke album. Voeg herinneringen toe 
            door hieronder een foto te uploaden!
          </p>
        </div>
        {/* <FileUploader /> */}
        {/* <GetFile/> */}
      </section>

      <section className="gallery-section">
        <h2>Verken prachtige momenten</h2>
        <div className="gallery">
          <img src="/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp" alt="Foto1" className="gallery-photo" />
          <img src="/images/76d92022-1465-407e-8ed0-746178d27182.webp" alt="Foto2" className="gallery-photo" />
          <img src="/images/8f875f0b-8908-4a3c-8fa6-5b43c023c742.webp" alt="Gallery 3" className="gallery-photo" />
        </div>
      </section>
    </div>
  );
};

export default HomePage;
